// src/App.js

import React from 'react';
import './App.css';
import ImageCanvas from './components/ImageCanvas';

function App() {
  return (
      <div className="App">
        <ImageCanvas />
      </div>
  );
}

export default App;
