// src/components/ImageCanvas.js

import React, { useState, useEffect } from 'react';

const ImageCanvas = () => {
    const [images, setImages] = useState([]);

    const fetchImages = async () => {
        try {
            const response = await fetch('https://obuda-irok.ninjacloud.hu/api/drawings'); // Adjust URL for your API
            const newImages = await response.json();

            // Add new images and remove deleted ones
            setImages(currentImages => {
                const updatedImages = newImages.filter(newImage =>
                    !currentImages.some(image => image.id === newImage.id)
                );

                const remainingImages = currentImages.filter(image =>
                    newImages.some(newImage => newImage.id === image.id)
                );

                return [...remainingImages, ...updatedImages];
            });
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    useEffect(() => {
        fetchImages();
        const interval = setInterval(fetchImages, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ width: '1920px', height: '1080px', position: 'relative' }}>
            {images.map(image => (
                <img
                    key={image.id}
                    src={'https://obuda-irok.ninjacloud.hu/storage/'+image.image}
                    alt={`Drawing ${image.id}`}
                    style={{ position: 'absolute', left: image.x_cord, top: image.y_cord, width: 274 }}
                />
            ))}
        </div>
    );
};

export default ImageCanvas;
